<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <div class="header-user">
        <div>
          <p>Hola {{ userName }}</p>
        </div>
        <div>
          <figure>
            <img
              :src="userAvatar"
              class="img-avatar"
              :alt="userName" />
          </figure>
        </div>
      </div>
    </template>\
    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center">
        <strong>Cuenta</strong>
      <!--        
      </b-dropdown-header>
      <b-dropdown-item><i class="fa fa-envelope-o" /> Messages
        <b-badge variant="success">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-tasks" /> Tasks
        <b-badge variant="danger">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-comments" /> Comments
        <b-badge variant="warning">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-header
        tag="div"
        class="text-center">
        <strong>Configuración</strong>
      -->
      </b-dropdown-header>
      <b-dropdown-item  @click.stop="perfil()"><i class="fa fa-user"/> Perfil</b-dropdown-item>
      <b-dropdown-item @click.prevent= 'pass' ><i class="fa fa-key" /> Password</b-dropdown-item>
      <!--<b-dropdown-item><i class="fa fa-wrench" /> Configuraciones</b-dropdown-item>-->
      <b-dropdown-item @click.prevent="logout" ><i class="fa fa-lock" /> Salir</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
export default {
  name: 'DefaultHeaderDropdownAccnt',
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return { 
      itemsCount: 42,
    }
  },
  computed: {
    userName: function() {
      // return this.camelize(this.$store.getters['user/nombre']);
      return this.$store.getters['user/nombre'];
    },
    userAvatar: function () {
      let avatar = 'img/avatars/man.png';
      // let server = process.env.VUE_APP_ROOT_API;
      // let avatar = server + '/img/avatars/man.png';
      
      // let userAvatar = this.$store.getters['user/avatar'].trim();
      // if (userAvatar) {
      //   if (userAvatar.trim() != '') {
      //     avatar = server + '/' + userAvatar;
      //   }
      // }
      return avatar;
    }
  },
  methods: {
    logout (){
      /* Logout del usuario */  
      this.$store.dispatch('user/logout');

      this.$router.push({ name: 'Login' })
    },
    perfil(){
      if (this.$store.getters['user/loggedIn'] ) {
        this.$router.push({ name: 'UsuarioPerfil' }); 
      }
    },
    pass() {
      this.$router.push({ name: 'UsuarioSelfPass' });
    },
  } 
}
</script>

<style>
  div.header-user {
    display: inline-block;
    margin: 0;
    margin-right: 20px;
    padding: 0;
  }
  div.header-user > div {
    display: inline-block;
    margin: 0;
    padding: 5px 5px 0px 5px;
  }
  div.header-user figure {
    width: 100%;
  }
  div.header-user figure img {
    width: 80%;
  }
</style>
