<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      
      <b-link class="navbar-brand" to="/">
        <img class="navbar-brand-full" src="logo.png" width="80" height="38" alt="Proprietas">
      </b-link>

      <SidebarToggler class="d-md-down-none" display="lg" />

      <div class="header-empresa">
        <p> {{ empresa }} </p>
      </div>

      <b-navbar-nav class="d-md-down-none">
        <!--<b-nav-item class="px-3" to="/dashboard">Dashboard</b-nav-item>-->
        <!--<b-nav-item class="px-3" to="/users" exact>Users</b-nav-item>-->
        <!--<b-nav-item class="px-3" to="/socios" exact>Socios</b-nav-item>-->
        <!--<b-nav-item class="px-3">Settings</b-nav-item>-->
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <!--
        <b-nav-item class="d-md-down-none">
          <i class="icon-bell"></i>
          <b-badge pill variant="danger">5</b-badge>
        </b-nav-item>

        <b-nav-item class="d-md-down-none">
          <i class="icon-list"></i>
        </b-nav-item>

        <b-nav-item class="d-md-down-none">
          <i class="icon-location-pin"></i>
        </b-nav-item>
        -->

        <DefaultHeaderDropdownAccnt/>
      </b-navbar-nav>

      <!-- Aside -->
      <!--<AsideToggler class="d-none d-lg-block" />-->
      
    </AppHeader>
    
    <div class="app-body">
      <!-- Spinner -->
      <div id="spinner" class="justify-content-center align-items-center"
          :style="{ height: spinnerHeight + 'px', 
            width: spinnerWidth + 'px' }" 
          v-if='spinnerVisible'>
        <b-spinner id="spinner-spin" label="Loading...">
        </b-spinner>
      </div>

      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="navItems"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list"/>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <DefaultAside/>
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a href="http://www.konzept.com.ar" target="_blank">konzept</a>
        <span class="ml-1">&copy; {{ anio }}</span>
      </div>
      <!--
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://coreui.io">CoreUI for Vue</a>
      </div>
      -->
    </TheFooter>
  </div>
</template>

<script>
import nav from '@/_nav';
// import nav_cuentas from '@/_nav_cuentas';
// import nav_produccion from '@/_nav_produccion';
// import nav_disenio from '@/_nav_disenio';
// import nav_asist_admin from '@/_nav_asist_admin';
// import nav_calidad from '@/_nav_calidad';
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter, Breadcrumb } from '@coreui/vue';
import DefaultAside from './DefaultAside';
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt';

export default {
  name: 'full',
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data () {
    return {
      // nav: nav.items,
      navItems: [],

      anio: (new Date()).getFullYear(),
      //spinnerHeight: 200
    }
  },
  mounted() {
    this.navItems = nav.items;
    // this.nav = this.$store.getters['user/nav'];

    // var userRol = this.$store.getters['user/rolId'];
    // /* Definimos el menu a partir del rol del usuario */
    // if (userRol == 4) {
    //   this.nav = nav_produccion.items;
    // } else if (userRol == 3) {
    //   this.nav = nav_cuentas.items;
    // } else if (userRol == 5) {
    //   this.nav = nav_disenio.items;
    // } else if (userRol == 7) {
    //   this.nav = nav_asist_admin.items;
    // } else if (userRol == 8) {
    //   this.nav = nav_calidad.items;
    // } else {
    //   this.nav = nav.items;
    // }
  },
  computed: {
    // navItems() {
    //   if (this.nav) {
    //     return this.nav;
    //   }
    //   return [];
    // },
    empresa() {
      return this.$store.getters['user/empresa'];
    },
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    },
    spinnerVisible: {
      get: function () {
        return this.$store.getters['spinnerProcesando/procesando'];
      },
      set: function (newValue) {
      }
    },
    spinnerHeight: {
      get: function () {
        return this.$store.getters['spinnerProcesando/height'];
      },
      set: function (newValue) {
      }
    },
    spinnerWidth: {
      get: function () {
        return this.$store.getters['spinnerProcesando/width'];
      },
      set: function (newValue) {
      }
    },
  }
}
</script>
