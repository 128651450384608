export default {
  items: [
    // {
    //   name: 'Inicio',
    //   url: '/',
    //   icon: 'icon-home',
    // },
    {
      name: 'Propiedades',
      url: '/propiedades',
      icon: 'icon-list'
    },
    {
      name: 'Tipos de Propiedades',
      url: '/tipos-propiedades',
      icon: 'icon-list'
    },
    {
      name: 'Características de Propiedades',
      url: '/caracteristicas-propiedades',
      icon: 'icon-list'
    },
    {
      name: 'Comentarios',
      url: '/comentarios',
      icon: 'icon-list'
    }
  ]
}